import React, { useEffect, useState } from 'react';
import './App.css';
import seedrandom from 'seedrandom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SxProps, Theme, Typography } from "@mui/material";
import { getUrlLangCode, runWhileActive } from './utils';
import { ArticleMeta, Challenge, fetchChallenge } from './api';
import { maxLives, maxShownTitles, optionCount } from './config';
import { str } from './strings';

function Header() {
  return <div>
    <Typography variant="h2">{str("title")}</Typography>
    <Typography variant="body1">{str("explanation")}</Typography>
    <LanguageSwitcher sx={{ marginBottom: "1em" }} />
  </div>;
}

function App() {
  const [date, setDate] = useState(new Date());
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  useEffect(() => {
    const stopper = runWhileActive(() => {
      const currentDate = new Date();
      if (currentDate.getDate() !== day || currentDate.getMonth() !== month || currentDate.getFullYear() !== year) {
        console.info(`Date changed to ${currentDate}`);
        setDate(currentDate);
      }
    }, 1000);
    return () => stopper();
  }, [day, month, year]);
  return <Content key={`${day}-${month}-${year}`} day={day} month={month} year={year} />;
}

function Content(props: { day: number, month: number, year: number }) {
  const { day, month, year } = props;
  const [challenge, setChallenge] = useState<Challenge | null>(null);
  const [shownTitlesCount, setShownTitlesCount] = useState(1);
  const [wronglyChosen, setWronglyChosen] = useState(new Set<string>());
  const [selectedCorrectOption, setSelectedCorrectOption] = useState(false);

  const livesLeft = maxLives - wronglyChosen.size;
  const shownTitlesLeft = maxShownTitles - shownTitlesCount;
  const livesLeftStr = "❤️".repeat(livesLeft);
  const shownTitlesLeftStr = "💡".repeat(shownTitlesLeft);
  const [userClosedGameOver, setUserClosedGameOver] = useState(false);
  const [userClosedWin, setUserClosedWin] = useState(false);
  const [shakingOption, setShakingOption] = useState<ArticleMeta | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [requestedHintDetailsForArticle, setRequestedHintDetailsForArticle] = useState<ArticleMeta | null>(null);
  const [requestedHintDetails, setRequestedHintDetails] = useState<string | null>(null);
  const [customSeed, setCustomSeed] = useState<number | undefined>(undefined);
  const [loadFailed, setLoadFailed] = useState(false);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      console.debug({ customSeed, normalSeed: `${day}-${month}-${year}` })
      const rngSupplier = () => seedrandom(customSeed ? customSeed.toString() : `${day}-${month}-${year}`);
      const loadedChallenge = await fetchChallenge(optionCount, rngSupplier);
      if (loadedChallenge === null) {
        setLoadFailed(true);
      } else {
        setLoadFailed(false);
        setChallenge(loadedChallenge);
        setShownTitlesCount(1);
        setWronglyChosen(new Set());
        setSelectedCorrectOption(false);
        setUserClosedGameOver(false);
        setUserClosedWin(false);
        setShakingOption(null);
        setRequestedHintDetailsForArticle(null);
        setRequestedHintDetails(null);
      }
      setIsLoading(false);
    })();
  }, [day, month, year, customSeed]);

  useEffect(() => {
    if (requestedHintDetailsForArticle !== null) {
      setRequestedHintDetails(null);
      (async function () {
        try {
          const response = await fetch(requestedHintDetailsForArticle.descriptionUrl!);
          if (response.ok) {
            const result = await response.json();
            setRequestedHintDetails(result.description);
          } else {
            setRequestedHintDetails("-");
          }
        } catch (e) {
          console.error(`Could not get hint details: ${e}`);
          setRequestedHintDetails("-");
        }
      })();
    }
  }, [requestedHintDetailsForArticle]);

  const shareData = {
    text: str("shareData", (wronglyChosen.size + 1), wronglyChosen.size === 0 ? str("shareDataAttempt") : str("shareDataAttempts"), shownTitlesCount, shownTitlesCount === 1 ? str("shareDataHint") : str("shareDataHints"))
  }

  if (loadFailed) {
    return <div className="App">
      <Header />
      <Typography sx={{ marginBottom: "1rem" }} variant="body1" color="red">{str("loadFailed")}<br />{str("pleaseReload")}</Typography>
      <Button variant="contained" onClick={() => window.location.reload()}>{str("reload")}</Button>
    </div>;
  }

  if (isLoading) {
    return <div className="App">
      <Header />
      <CircularProgress sx={{ margin: "2em" }} />
    </div>;
  }

  // After we've loaded at least once the challenge should be non-null
  const { options, correctOption, referencedArticles } = challenge!;

  const takeMeThere = <Button target="_blank" href={correctOption.webContentUrl}>{str("readTheArticle")}</Button>

  return (
    <div className="App">
      <Header />
      <Typography variant="h5">{str("hints")}</Typography>
      <Typography sx={{ marginBottom: "0.5em" }} variant="body1">{customSeed === undefined ? str("hintExplanationDaily") : str("hintExplanationRandom")}</Typography>
      <div style={{ marginBottom: "1em", display: "flex", flexDirection: "row", "flexWrap": "wrap", justifyContent: "center", alignItems: "center", gap: "0.5em" }}>
        {[...Array(shownTitlesCount).keys()].map(i => referencedArticles[i]).map(referencedArticle => {
          return <Chip key={referencedArticle.name} variant="outlined" label={referencedArticle.name} onClick={() => setRequestedHintDetailsForArticle(referencedArticle)} />
        })}
      </div>
      <Typography sx={{ marginBottom: "0.5em" }} variant="h5">{str("answerOptions")}</Typography>
      <div style={{ marginBottom: "1em", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center", gap: "0.5em" }}>
        {options.map(option => {
          const isCorrectOption = correctOption.name === option.name;
          let color: "success" | "error" | "primary";
          if (isCorrectOption && selectedCorrectOption) {
            color = "success";
          } else if (wronglyChosen.has(option.name)) {
            color = "error";
          } else {
            color = "primary";
          }
          const shake = shakingOption === option;
          return <div className={shake ? "shake" : undefined} key={option.name}><Button color={color} variant="outlined" onClick={() => {
            if (livesLeft === 0 || selectedCorrectOption) {
              return;
            }

            if (isCorrectOption) {
              setSelectedCorrectOption(true);
            } else {
              setWronglyChosen(new Set(wronglyChosen).add(option.name));
              setShakingOption(option);
            }
          }}>{option.name}</Button></div>
        })}
      </div>
      <div style={{ display: "flex", marginBottom: "0.5em", justifyContent: "center", gap: "0.5em" }}>
        <div>{livesLeft === 0 ? <></> : <Chip variant="outlined" label={livesLeftStr} />}</div>
        <div>{shownTitlesLeft === 0 ? <></> : <Chip variant="outlined" label={shownTitlesLeftStr} />}</div>
      </div>
      <div><Button sx={{ marginBottom: "0.5em" }} disabled={shownTitlesLeft === 0 || selectedCorrectOption || livesLeft === 0} variant="contained" onClick={() => setShownTitlesCount(shownTitlesCount + 1)}>{str("showMoreHints")}</Button></div>
      {customSeed === undefined && (selectedCorrectOption || livesLeft === 0) ? <Button variant="contained" onClick={() => setCustomSeed(Math.random())}>{str("playRandomly")}</Button> : <></>}
      {customSeed !== undefined && (selectedCorrectOption || livesLeft === 0) ? <Button variant="contained" onClick={() => setCustomSeed(Math.random())}>{str("nextGame")}</Button> : <></>}
      {customSeed !== undefined ? <Button sx={{ margin: "0.5em" }} variant="contained" onClick={() => setCustomSeed(undefined)}>{str("backToDailyGame")}</Button> : <></>}
      <Dialog open={livesLeft === 0 && !userClosedGameOver}>
        <DialogTitle>{str("gameOver")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {str("drainedLives")} <i>{correctOption.name}</i>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {takeMeThere}
          <Button onClick={() => setUserClosedGameOver(true)} autoFocus>
            {str("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={selectedCorrectOption && !userClosedWin}>
        <DialogTitle>{str("youWon")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {str("congrats", (wronglyChosen.size + 1), wronglyChosen.size === 0 ? str("shareDataAttempt") : str("shareDataAttempts"), shownTitlesCount, shownTitlesCount === 1 ? str("shareDataHint") : str("shareDataHints"))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {takeMeThere}
          {customSeed === undefined && navigator.canShare && navigator.canShare(shareData) ? <Button onClick={async () => {
            await navigator.share(shareData);
          }}>{str("shareScore")}</Button> : <></>}
          <Button onClick={() => setUserClosedWin(true)} autoFocus>
            {str("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={requestedHintDetailsForArticle !== null}>
        <DialogTitle>{requestedHintDetailsForArticle?.name}</DialogTitle>
        <DialogContent>
          {requestedHintDetails === null ?
            <CircularProgress /> :
            <DialogContentText>
              {requestedHintDetails}
            </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRequestedHintDetailsForArticle(null)} autoFocus>
            {str("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function LanguageSwitcher(props: { sx?: SxProps<Theme> }) {
  const langCode = getUrlLangCode();
  return <Button sx={props.sx} variant="text" href={langCode === "en" ? "/?lang=de" : "/?lang=en"}>{langCode === "en" ? "Spiele auf Deutsch" : "Play in English"}</Button>
}

export default App;
