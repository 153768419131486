import { getUrlLangCode } from "./utils";

export interface ArticleMeta {
    name: string;
    contentUrl: string;
    descriptionUrl?: string;
    webContentUrl: string;
}

export interface Challenge {
    options: ArticleMeta[];
    correctOption: ArticleMeta;
    referencedArticles: ArticleMeta[];
}

export type Rng = () => number;

export async function fetchChallenge(optionCount: number, rngSupplier: () => Rng): Promise<Challenge | null> {
    const maxRetries = 5;
    for (let i = 0; i < maxRetries; i++) {
        if (i > 0) {
            console.info("Retrying...");
        }
        try {
            const rng = rngSupplier();
            const options = await fetchOptions(optionCount, rng);
            const correctOptionIdx = Math.floor(rng() * (options.length - 1));
            const correctOption = options[correctOptionIdx];
            const referencedArticles = await fetchReferencedArticlesFromPage(correctOption.name, correctOption.contentUrl);
            shuffle(referencedArticles, rng);
            return { options, correctOption, referencedArticles };
        } catch (e) {
            console.error("Failed to load!");
            if (i + 1 < maxRetries) {
                await sleep(500);
            }
        }
    }

    return null;
}

class LanguageConfig {
    private bannedArticlePrefixes: Set<string>;
    private code: string;
    featuredArticlesUrl: string;
    featuredLinksSelector: string;

    constructor(code: string, bannedArticlePrefixes: Set<string>, featuredArticlesUrl: string, featuredLinksSelector: string) {
        this.code = code;
        this.bannedArticlePrefixes = bannedArticlePrefixes;
        this.featuredArticlesUrl = featuredArticlesUrl;
        this.featuredLinksSelector = featuredLinksSelector;
    }

    isBanned(articleName: string) {
        for (const bannedPrefix of this.bannedArticlePrefixes) {
            if (articleName.startsWith(bannedPrefix)) {
                return true;
            }
        }
        return false;
    }

    getApiArticleContentUrl(contentId: string) {
        return `https://api.wikimedia.org/core/v1/wikipedia/${this.code}/page/${contentId}/html`;
    }

    getWebArticleContentUrl(contentId: string) {
        return `https://${this.code}.wikipedia.org/wiki/${contentId}`;
    }

    getApiArticleDescriptionUrl(contentId: string) {
        return `https://api.wikimedia.org/core/v1/wikipedia/${this.code}/page/${contentId}/description`;
    }

    getStandardArticlePrefix() {
        return `https://${this.code}.wikipedia.org/wiki/`;
    }
}

const languageConfigs: { [lang: string]: LanguageConfig; } = {
    "en": new LanguageConfig("en",
        new Set(["Wikipedia:", "Special:", "Portal:", "Help:", "Template:"]),
        "https://api.wikimedia.org/core/v1/wikipedia/en/page/Wikipedia:Featured_articles/html",
        "span.featured_article_metadata > a"),
    "de": new LanguageConfig("de",
        new Set(["Wikipedia:", "Special:", "Portal:", "Help:", "Template:", "Spezial:", "Hilfe:"]),
        "https://api.wikimedia.org/core/v1/wikipedia/de/page/Wikipedia:Exzellente_Artikel/html",
        "tbody a[rel='mw:WikiLink']")
}

function getLanguageConfig() {
    const langParamValue = getUrlLangCode();
    if (langParamValue !== null && langParamValue in languageConfigs) {
        return languageConfigs[langParamValue];
    } else {
        return languageConfigs["en"];
    }
}

function shuffle(array: any[], rng: Rng) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(rng() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function fetchOptions(optionCount: number, rng: Rng) {
    const langConfig = getLanguageConfig();
    const url = langConfig.featuredArticlesUrl;
    const fetched = await (await fetch(url)).text();
    const parser = new DOMParser();
    const parsed = parser.parseFromString(fetched, "text/html");
    const featuredLinks = parsed.querySelectorAll(langConfig.featuredLinksSelector);
    const options: ArticleMeta[] = [];
    for (let i = 0; i < optionCount; i++) {
        const idx = Math.floor(rng() * (featuredLinks.length - 1));
        const selectedLink = featuredLinks.item(idx);
        const name = selectedLink.getAttribute("title")!;
        const href = selectedLink.getAttribute("href")!;
        const contentId = href.startsWith("./") ? href.substring(2) : href;
        const contentUrl = langConfig.getApiArticleContentUrl(contentId);
        const webContentUrl = langConfig.getWebArticleContentUrl(name);
        options.push({ name, contentUrl, webContentUrl });
    }
    return options;
}

function distinctBy<T>(arr: T[], keySelector: (t: T) => any): T[] {
    return [...new Map(arr.map(item => [keySelector(item), item])).values()]
}

async function fetchReferencedArticlesFromPage(articleName: string, url: string): Promise<ArticleMeta[]> {
    const langConfig = getLanguageConfig();
    const fetched = await (await fetch(url)).text();
    const parser = new DOMParser();
    const parsed = parser.parseFromString(fetched, "text/html");
    const links = Array.from(parsed.querySelectorAll("a[rel='mw:WikiLink']"));
    const articles = links.map(link => link as HTMLAnchorElement)
        .map(link => {
            // TODO Deduplicate code
            const href = link.href;
            let contentId = href.startsWith("./") ? href.substring(2) : href;
            contentId = contentId.startsWith(langConfig.getStandardArticlePrefix()) ? contentId.substring(langConfig.getStandardArticlePrefix().length) : contentId;
            contentId = contentId.indexOf("#") !== -1 ? contentId.substring(0, contentId.indexOf("#")) : contentId;
            const contentUrl = langConfig.getApiArticleContentUrl(contentId);
            const descriptionUrl = langConfig.getApiArticleDescriptionUrl(contentId);
            const webContentUrl = langConfig.getWebArticleContentUrl(contentId);
            return {
                name: link.title && link.title !== "" ? link.title : link.innerText,
                contentUrl,
                descriptionUrl,
                webContentUrl
            }
        })
        .filter(article => article.name && article.name !== "" && !langConfig.isBanned(article.name) && !article.name.toLowerCase().includes(articleName.toLowerCase()));
    articles.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    return distinctBy(articles, article => article.name);
}