export function runWhileActive(f: () => void, intervalMs: number): () => void {
    let cancelToken = -1;
    if (!document.hidden) {
        cancelToken = window.setInterval(f, intervalMs);
    }

    const listener = () => {
        if (!document.hidden && cancelToken === -1) {
            console.debug("Became visible.");
            cancelToken = window.setInterval(f, intervalMs);
        } else if (cancelToken !== -1) {
            console.debug("Became hidden.");
            window.clearInterval(cancelToken);
            cancelToken = -1;
        }
    };

    document.addEventListener("visibilitychange", listener, false);

    return () => {
        document.removeEventListener("visibilitychange", listener);
        if (cancelToken !== -1) {
            window.clearInterval(cancelToken);
            cancelToken = -1;
        }
    };
}

export function getUrlLangCode() {
    const urlParams = new URLSearchParams(window.location.search);
    const langParamValue = urlParams.get("lang");
    if (langParamValue !== null) {
        return langParamValue;
    } else {
        return "en";
    }
}