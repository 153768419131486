import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

(function () {
  if (window.location.hostname === "wquiz.cbruegg.com") {
    window.location.hostname = "wuizz.cbruegg.com";
    return;
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})();