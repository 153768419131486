import { getUrlLangCode } from "./utils";

const strings = {
    "title": "Wuizz",
    "explanation": {
        "en": "Find out which Wikipedia article is the right one!",
        "de": "Finde heraus, welcher Wikipedia-Artikel der Richtige ist!"
    },
    "hints": {
        "en": "Hints",
        "de": "Tipps"
    },
    "answerOptions": {
        "en": "Answer Options",
        "de": "Antwortmöglichkeiten"
    },
    "backToDailyGame": {
        "en": "Back to Daily Game",
        "de": "Zurück zum Tagesspiel"
    },
    "playRandomly": {
        "en": "Play Randomly",
        "de": "Zufällig spielen"
    },
    "nextGame": {
        "en": "Next Game",
        "de": "Nächstes Spiel"
    },
    "close": {
        "en": "Close",
        "de": "Schließen"
    },
    "shareScore": {
        "en": "Share Score",
        "de": "Ergebnis teilen"
    },
    "youWon": {
        "en": "You did it!",
        "de": "Du hast es geschafft!"
    },
    "gameOver": {
        "en": "Game Over!",
        "de": "Game Over!"
    },
    "congrats": {
        "en": "Congratulations for finding the correct answer! You found the correct answer in {0} {1} with {2} {3}!",
        "de": "Herzlichen Glückwunsch! Du hast die richtige Antwort in {0} {1} mit {2} {3} gefunden."
    },
    "shareData": {
        "en": "I found the correct answer on Wuizz in {0} {1} with {2} {3}!",
        "de": "Ich habe die richtige Antwort auf Wuizz in {0} {1} mit {2} {3} gefunden."
    },
    "shareDataHint": {
        "en": "hint",
        "de": "Tipp"
    },
    "shareDataHints": {
        "en": "hints",
        "de": "Tipps"
    },
    "shareDataAttempt": {
        "en": "attempt",
        "de": "Versuch"
    },
    "shareDataAttempts": {
        "en": "attempts",
        "de": "Versuchen"
    },
    "loadFailed": {
        "en": "Sorry, the challenge could not be loaded :(",
        "de": "Entschuldige, die Challenge konnte nicht geladen werden :("
    },
    "pleaseReload": {
        "en": "Please reload the page and try again.",
        "de": "Bitte lade die Seite erneut und probiere es nochmal."
    },
    "reload": {
        "en": "Reload",
        "de": "Neu laden"
    },
    "readTheArticle": {
        "en": "Read the article",
        "de": "Artikel lesen"
    },
    "hintExplanationDaily": {
        "en": "Today's article references the following topics. Tap to learn more.",
        "de": "Der heutige Artikel enthält die folgenden Verweise. Tippe sie an um mehr zu erfahren."
    },
    "hintExplanationRandom": {
        "en": "The article references the following topics. Tap to learn more.",
        "de": "Der Artikel enthält die folgenden Verweise. Tippe sie an um mehr zu erfahren."
    },
    "drainedLives": {
        "en": "You've drained all your lives. The correct answer was:",
        "de": "Du hast alle deine Leben verbraucht. Die richtige Antwort war:"
    },
    "showMoreHints": {
        "en": "Show more hints",
        "de": "Zeige mehr Tipps"
    }
}

export function str(key: keyof typeof strings, ...args: any[]): string {
    const value = strings[key];
    let result = "";
    if (typeof value === 'string') {
        result = value;
    } else {
        const langCode = getUrlLangCode();
        if (langCode in value) {
            result = (value as any)[langCode];
        } else {
            result = value["en"];
        }
    }

    if (args.length === 0) {
        return result;
    } else {
        return result.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number].toString()
                : match;
        });
    }
}